import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';

const Index = ({ name, link }) => {

    return (
        <Link className='link__btn__main' to={link}>
            -&nbsp;{name}
        </Link>
    )
}

export default Index