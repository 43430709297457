import React from 'react'

const Index = ({ type, onChange, name, placeholder, textarea }) => {
    return (
        <>
            {textarea ? <textarea rows={4} name={name} type={type} onChange={onChange} class="form-control" aria-describedby="emailHelp" placeholder={placeholder} /> :
                <input name={name} type={type} onChange={onChange} class="form-control" aria-describedby="emailHelp" placeholder={placeholder} />}
        </>
    )
}

export default Index