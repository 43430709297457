import React from 'react';
import './style.scss';
import { Footer, Navbar } from '../../components';
import { DbtHero, DbtOffers } from '../../layouts';

const Index = () => {
    return (
        <div>
            <Navbar />
                <DbtHero />
                <DbtOffers />
            <Footer />
        </div>
    )
}

export default Index
