import React from 'react';
import './style.scss';

const Index = ({data}) => {
  return (
    <div className='challenge__card__main'>
        <h5>
            {data?.percent}%
        </h5>
        <p>
            {data?.description}
        </p>
        <a href={data?.link} target='_blank' rel="noreferrer">
            {data?.linkName}
        </a>
    </div>
  )
}

export default Index