import React from 'react';
import './style.scss';

const Index = ({ text, onClick, classes }) => {
  return (
    <button onClick={onClick} className={`${classes ?? ""} primary__btn__main`}>
      {text}
    </button>
  )
}

export default Index
