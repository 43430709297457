import React from 'react';
import './style.scss';

const Index = ({ videoUrl, thumbnail }) => {
    return (
        <video poster={thumbnail} muted loop autoPlay playsInline className='video__main'>
            <source src={videoUrl}>
            </source>
        </video>
    )
}

export default Index
