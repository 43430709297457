import React, { useEffect, useState } from 'react';
import './style.scss';
import logo from '../../assets/images/logo.png';
import { ButtonWithoutOutline, ContactUsModal } from '..';
import exploreBtn from '../../assets/images/exploreBtn.png';
import { Link } from 'react-router-dom';
import { IoIosMenu } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import InnovationDropdown from './innovationList';


const Index = ({ isAbsolute, snowflakeBg }) => {

  const [menuOpen, setMenuOpen] = useState(false)

  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
  const [contactModal, setContactModal] = useState(false)

  const tabs = [
    {
      name: "snowflake",
      link: "/snowflake"
    },
    // {
    //   name: "Azure",
    //   link: "/"
    // },
    {
      name: "dbt",
      link: "/dbt"
    },
    {
      name: " Innovation",
      link: "/innovation",
      type:"innovation"
    },
    {
      name: "Contact Us",
      link: "/",
      type: "contact"
    },
  ]

  const toggleMenu = () => setMenuOpen(o => !o)

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 769)
    })
  }, [])

  const onClose = () => setContactModal(false)
  const openModal = () => {
    setContactModal(true)
    setMenuOpen(false)
  }

  return (
    <div className={`navbar__main ${isAbsolute ? "isAbsolute" : ""} ${snowflakeBg ? "snowflakeBg" : ""}`}>
      {!isMobile ? <div className='custom__container navbar__flex__section'>
        <div className='left__section'>
          <div className='logo__section'>
            <Link to={"/"}>
              <img src={logo} alt='logo' />
            </Link>
          </div>
        </div>
        <div className='nav__tabs__section'>
          {tabs?.map((v, i) => {
            return <>
              {v?.type === "contact" ?
                <ButtonWithoutOutline onClick={openModal} text={v?.name} key={i} /> :
                v?.type === "innovation" ? <InnovationDropdown />:
                <Link key={i} to={v?.link}>
                  <ButtonWithoutOutline text={v?.name} />
                </Link>
              }
            </>
          })}
        </div>
        <div className='right__section'>
          <div className='explore__btn'>
          {/* <Link target='_blank' to={links?.manimumCloud}> */}
            <img src={exploreBtn} alt='' />
          </div>
        </div>
      </div>
        :
        <div className='mob__nav__main'>
          <div className='menu__and__logo custom__container py-4'>
            <div>
              <Link to={"/"}>
                <img src={logo} alt='logo' />
              </Link>
            </div>
            <div>
              <button onClick={toggleMenu} className='toggle__menu__btn' >
                {menuOpen ? <IoMdClose color='#fff' size={40} /> : <IoIosMenu color='#fff' size={40} />}
              </button>
            </div>
          </div>

          <div className='custom__container overflow-hidden'><div className={`${menuOpen ? "actie__menu" : ""} open__menu`}>
            {tabs?.map((v, i) => {
              return <div key={i}>
                {v?.type === "contact" ? 
                <ButtonWithoutOutline  onClick={openModal} text={v?.name} key={i} />
                : <Link to={v?.link}>
                  {v?.name}
                </Link>}
              </div>
            })}
          </div>
          </div>
        </div>}
      <ContactUsModal modalShow={contactModal} onClose={onClose} />
    </div>
  )
}

export default Index
