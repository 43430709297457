import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './styles.scss';


export default function Index({ slides }) {
  return (
    <>
      <Swiper
        slidesPerView={4}
        spaceBetween={30}
        grabCursor={true}
        className="multi__carousel"
        autoplay={{
          delay: 2000
        }}
      >
        {slides?.map((v, i) => {
          return <SwiperSlide>
            <img src={v?.image} style={{ width: v?.width && v?.width, maxHeight:v?.maxHeight && v?.maxHeight }} alt='' />
          </SwiperSlide>
        })}
      </Swiper>
    </>
  );
}
