import React from 'react';
import './style.scss';
import heroBg from '../../../assets/images/heroBg.png'
import { BlogCard } from '../../../components';


const Index = () => {

    const cards = [
        {
            title: "Industry Solution,",
            description: "Optimizing Sales Forecasting by Harnessing Snowflake Cortex and AI for Precision and Efficiency at RNDC",
            img: heroBg
        },
        {
            title: "Financial Services",
            description: "Efficient Data Retrieval in Insurance",
            img: heroBg
        },
        {
            title: "Wholesale Distribution",
            description: "Sales Forecasting in Distribution",
            img: heroBg
        },
        {
            title: "Civil Engineering",
            description: "Predictive Safety in Construction",
            img: heroBg
        },
        {
            title: "Financial Services, Technology",
            description: "Germania Insurance: Snowflake Cost Optimization",
            img: heroBg
        },
        {
            title: "Financial Services, Technology",
            description: "Juvo Mobile: Maximizing the Value of the Data Cloud with 75% Cost Efficiency",
            img: heroBg
        },
        {
            title: "Healthcare & Life Sciences",
            description: "Blue Yonder: Handling Millions of Records for a Digital Supply Chain Solution",
            img: heroBg
        },
        {
            title: "Industry Solution, Manufacturing",
            description: "Oil & Gas Well Data Analysis Natively in Snowflake",
            img: heroBg
        },
        {
            title: "Financial Services, Industry Solution",
            description: "Insurance DataHub – Guidewire CDA Adapter",
            img: heroBg
        },
        {
            title: "Healthcare & Life Sciences, Industry Solution",
            description: "Health DataHub – FHIR Integration",
            img: heroBg
        },
        {
            title: "Industry Solution, Media and Entertainment",
            description: "Marketing Campaign Intelligence Solution",
            img: heroBg
        },
    ]

    return (
        <div className='blog__section__main'>
            <div className='custom__container'>
                <div className='blogs__section'>
                    {cards?.map((v,i)=>{
                        return <BlogCard data={v} key={i} />
                    })}
                </div>
            </div>
        </div>
    )
}

export default Index