import React from 'react'
import './style.scss'
import { IoIosSend } from "react-icons/io";


const Index = () => {
  return (
    <div className='newsletter__input'>
        <input placeholder='Enter Email' />
        <button>
            <IoIosSend />
        </button>
    </div>
  )
}

export default Index