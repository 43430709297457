import React from 'react';
import './style.scss';


const Index = () => {

    const cards = [
        {
            title: "Evaluation",
            subTitle: "Targeted Deep-Dive Exploration Facilitating End-to-End Data Strategy",
            objectives: [
                "Create MVPs and use cases based on business needs.",
                "Make an assessment of the present data landscape.",
                "Create the forward-thinking suggested remedy.",
                "Give a thorough implementation schedule.",
            ]
        },
        {
            title: "Implementation",
            subTitle: "We Bring Our Skilled, Qualified Staff to Provide Complete Solutions",
            objectives: [
                "Ensure comprehensive coverage of the data lifecycle and offer profound technical proficiency.",
                "Keep an eye on the deliverables and concentrate on creating value iteratively.",
                "Lead by example and advance the data maturity curve.",
            ]
        },
        {
            title: "Optimization",
            subTitle: "Targeted Actions to Make Snowflake and Data Optimization Possible",
            objectives: [
                "Complete the Health Check with a variety of in-house tools.",
                "Find suggestions and areas for improvement.",
                "Make the comprehensive optimization plan and rank it.",
                "Create genuine value by enhancing adoption, boosting performance, and reducing expenses.",
            ]
        },
    ]

    return (
        <div className='strategies__main'>
            <div className='custom__container'>
                <div className='header__section'>
                    <h2>
                    Customized Information Approaches. Method That Is Important.    
                    </h2>
                </div>
                <div className='cards__section'>
                    {cards?.map((v, i) => {
                        return <div key={i} className={`${i === 1 ? "second__card" : ""} card__custom`}>
                            <div className='count__ring'>
                                {i + 1}
                            </div>
                            <div className='content__section'>
                                <h3>
                                    {v?.title}
                                </h3>
                                <div className='subtitle__container'>
                                    <p>
                                        {v?.subTitle}
                                    </p>
                                </div>
                                <h5>
                                    Objectives
                                </h5>
                                <div className='objective__section'>
                                    {v?.objectives?.map((e, i) => {
                                        return <div className='objective__card' key={i}>
                                            <div>
                                                <div className='dot' />
                                            </div>
                                            <div>
                                                {e}
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default Index