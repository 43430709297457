import { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';

function Index() {

    const [show, setShow] = useState(false);

    const handleMouseEnter = () => {
        setShow(true);
    };

    const handleMouseLeave = () => {
        setShow(false);
    };

    return (
        <Dropdown
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            show={show}
        >
            <Dropdown.Toggle className='bg-transparent border-0' id="dropdown-basic">
                Innovation
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item>
                    <Link to={"/innovation"}>
                        Innovation
                    </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                    <Link to={"/success-stories"}>
                        Success Stories
                    </Link>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default Index;