import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import './style.scss';
import { LuPhoneCall } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { ContactFormInput, PrimaryBtn } from '../..';
import { IoClose } from 'react-icons/io5';
import { MdOutlineMail } from "react-icons/md";




function MyVerticallyCenteredModal(props) {
    const { onClose } = props;

    const modalRef = useRef(null)

    const links = [
        {
            linkText: "+91 9346098889",
            link: "tel:+919346098889",
            icon: <LuPhoneCall />
        },
        {
            linkText: "support@synnthai.com",
            link: "mailto:support@synnthai.com",
            icon: <MdOutlineMail />
        },
    ]

    // const cards = [
    //     snowflake,
    //     aws,
    //     azure,
    //     dbt,
    //     snowflake,
    //     aws,
    //     azure,
    //     dbt,
    //     snowflake,
    //     aws,
    //     azure,
    //     dbt,
    //     snowflake,
    //     aws,
    //     azure,
    //     dbt,
        
    // ]

    const [modalHeight, setModalHeight] = useState(modalRef?.current?.clientHeight);

    useEffect(() => {
        setModalHeight(modalRef?.current?.clientHeight)
    }, [modalRef])


    console.log("Moda height,", { modalHeight, modalRef: modalRef?.current })

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body ref={modalRef} className='contact__us__modal__main'>
                <button className='close__btn' onClick={onClose}>
                    <IoClose size={25} />
                </button>
                <div className='contact__details__section'>
                    <h3>
                        Business Consulting Company 
                    </h3>
                    <p>
                        We're the best Business Consulting company in India.
                    </p>
                    <div className='links__section'>
                        {
                            links?.map((v, i) => {
                                return <Link key={i} to={v?.link}>
                                    <div className='icon__container'>
                                        {v.icon}
                                    </div>
                                    <p>
                                        {v?.linkText}
                                    </p>
                                </Link>
                            })
                        }
                    </div>
                    {/* <div className='cards__container'>
                        {cards?.map((v, i) => {
                            return <div key={i} style={{ "--i": i + 1 }} className='cards__c'>
                                <img alt='' src={v} />
                            </div>
                        })}
                    </div> */}
                </div>
                <div className='form__section'>
                    <h3>
                        Get FREE Quote
                    </h3>
                    <ContactFormInput placeholder={"Full Name"} name={"fullName"} />
                    <ContactFormInput placeholder={"Your Email*"} name={"email"} />
                    <ContactFormInput placeholder={"Phone*"} name={"phone"} />
                    <ContactFormInput placeholder={"Your Requirements*"} name={"description"} textarea />
                    <PrimaryBtn text={"Get FREE Quote"} classes={"w-100"} />
                </div>
            </Modal.Body>
        </Modal>
    );
}

function Index({ modalShow, onClose }) {

    return (
        <>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={onClose}
                onClose={onClose}
            />
        </>
    );
}

export default Index;