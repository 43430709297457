import React from 'react';
import './style.scss';
import testimonialImg from '../../../assets/images/testimonials/jeff2.png';
import { TestomonialCarousel, VideoModal } from '../../../components';
import thumbnail1 from '../../../assets/images/thumbnails/client-pic-a.jpg'
import thumbnail2 from '../../../assets/images/thumbnails/client-pic.jpg'
import { FaCirclePlay } from "react-icons/fa6";


const Index = () => {

    const [videoId, setVideoId] = React.useState(null);

    const testimonials = [
        {
            description: "“Our clients rely on us for technology solutions that enable operational efficiencies and deliver transformational growth to their business. Our customer-first mindset and commitment to their success are at the heart of everything we do.”",
            // name: "Jeff Lackey",
            title: "President & CEO, Synth Lake - NY, USA",
            image: testimonialImg
        },
        {
            description: "“Our clients rely on us for technology solutions that enable operational efficiencies and deliver transformational growth to their business. Our customer-first mindset and commitment to their success are at the heart of everything we do.”",
            // name: "Jeff Lackey",
            title: "President & CEO, Synth Lake - NY, USA",
            image: testimonialImg
        },
        {
            description: "“Our clients rely on us for technology solutions that enable operational efficiencies and deliver transformational growth to their business. Our customer-first mindset and commitment to their success are at the heart of everything we do.”",
            // name: "Jeff Lackey",
            title: "President & CEO, Synth Lake - NY, USA",
            image: testimonialImg
        },
        {
            description: "“Our clients rely on us for technology solutions that enable operational efficiencies and deliver transformational growth to their business. Our customer-first mindset and commitment to their success are at the heart of everything we do.”",
            // name: "Jeff Lackey",
            title: "President & CEO, Synth Lake - NY, USA",
            image: testimonialImg
        },
    ]

    const videos = [
        {
            name: "Cina Cleaves",
            video: "SZEflIVnhH8",
            thumbnail: thumbnail2
        },
        {
            name: "Jokvch Marlin",
            video: "SZEflIVnhH8",
            thumbnail: thumbnail1
        }
    ]

    const updateVideoId = (id) => setVideoId(id);
    const closeVdeo = () => setVideoId(null);

    return (
        <div className='testimonial__main'>
            <div className='custom__container'>
                <h4>
                    What our clients say about synthlake.
                </h4>
                <h5>
                    Over 1200+ Satisfied Clients and Growing
                </h5>
                <div className='testimonial__flex__section'>
                    <div className='video__container'>
                        <div className='w-100 d-flex justify-content-around'>
                            {videos?.map((v, i) => {
                                return <button onClick={()=> updateVideoId(v?.video)} className='video__thumbnail' style={{ backgroundImage: `url(${v?.thumbnail})` }} key={i}>
                                    <div className='flex__box'>
                                        <div className='play__btn__container'><FaCirclePlay color='#fff' size={22} /></div>
                                        <p>
                                            {v?.name}
                                        </p>
                                    </div>
                                </button>
                            })}
                            {/* <iframe width="45%" style={{aspectRatio:2/1.3}} src="https://www.youtube.com/embed/SZEflIVnhH8?si=KMYjMvWaWnkAYhbz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            <iframe width="45%" style={{aspectRatio:2/1.3}} src="https://www.youtube.com/embed/SZEflIVnhH8?si=o52mUlhGTASGyfIL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                        </div>
                    </div>
                    <div className='carousel__container'>
                        <TestomonialCarousel slides={testimonials} />
                    </div>
                </div>
            </div>
            {videoId !== null && <VideoModal onClose={closeVdeo} videoId={videoId} />}
        </div>
    )
}

export default Index
