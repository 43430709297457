import React from 'react';
import './style.scss';
import serviceman from '../../../assets/images/3951510.jpg';
import { PrimaryBtn } from '../../../components';


const Index = () => {
    return (
        <div className='meet__our__team__main'>
            <div className='custom__container'>
                <div className='flex__section'>
                    <div className='content__section'>
                        <h5>
                            Expericence matters
                        </h5>
                        <p>
                            10+ snowflake<br />certified consultants
                        </p>
                        <p className='description'>
                            We anticipate <span>challenges</span>, understand your goals, and <span>deliver results</span>.
                        </p>
                        <PrimaryBtn text={"Meet Our Team"} classes={"mt-4 snowflake__btn"} />
                    </div>
                    <div className='image__section'>
                        <img src={serviceman} alt='' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index