import React from 'react';
import './style.scss';
import { Footer, Navbar } from '../../components';
import { SuccessStoriesHero, SuccessStorisBlogs } from '../../layouts';

const Index = () => {
  return (
    <div>
        <Navbar />
        <SuccessStoriesHero />
        <SuccessStorisBlogs />
        <Footer />
    </div>
  )
}

export default Index