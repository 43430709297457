import React from 'react';
import './style.scss';
import { Footer, Navbar } from '../../components';
import { SuccessStoriesHero, SuccessStorisBlogs, SuccessStoryArticle } from '../../layouts';
import { cardData } from '../../utils/constants';
import { useParams } from 'react-router-dom';

const Index = () => {

  const param = useParams();
  const id = param.id;

  const story = cardData.find((item) => Number(item.id) === Number(id));
  console.log("Story",story)

  return (
    <div>
        <Navbar />
        <SuccessStoriesHero hideDescription title={story?.title} />
        <SuccessStoryArticle list={story?.list} />
        <Footer />
    </div>
  )
}

export default Index