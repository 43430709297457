import cardImg from '../assets/images/heroBg.png'

export const links = {
    manimumCloud: "https://maximumcloudsolutions.com/"
}

export const cardData = [
    {
        title: "Handling Millions of Records for a Digital Supply Chain Solution",
        description: "Handling Millions of Records for a Digital Supply Chain Solution",
        image: cardImg,
        id: 1,
        list: [
            {
                title: "Overview",
                text: "As online B2B activities surge, digital Supply Chain Management (SCM) solutions face increasing demands. The explosion of data generated by each client requires organizations to efficiently record, process, and analyze vast volumes of data at an accelerated pace. The success of SCM solutions is now heavily reliant on the robustness of their underlying data infrastructure.",
                image: cardImg
            },
            {
                title: "Customer Background",
                text: "SynthAI is a global leader in digital supply chain and omnichannel commerce fulfilment. Their intelligent, end-to-end platform empowers retailers, manufacturers, and logistics providers to accurately predict demand, adapt to changes, and efficiently fulfil customer needs. SynthAI its clients to automate processes that drive profitable business decisions, foster growth, and transform customer experiences. However, as SynthAI business expanded, their existing data pipeline struggled to manage millions of internal and customer data records. This resulted in extended data load times during transfers, leading to delays in processing and limited data accessibility for both internal and external stakeholders. The systems in place were unable to handle the increased data load, negatively impacting data accessibility. Consequently, the company faced challenges in making timely data-driven decisions, slowing down innovation and overall business growth.",
                // image: cardImg
            },
            {
                title: "Our Solution & Approach",
                text: "SynthAI employed a four-stage approach to expedite data availability for sharing-as-a-service. This iterative process established a data pipeline framework centred on continuous data ingestion, cleansing, and modelling.",
                image: cardImg
            },
            {
                title: "Understanding Business Priorities ",
                text: "SynthAI SCM solution spans from supply chain planning to execution, with a particular emphasis on warehouse management. Our team focused on understanding the key aspects of supply chain execution. The solution required a scalable and high-performance data storage system to meet these demands.",
                image: cardImg
            },
            {
                title: "Centralizing Data on the Snowflake Cloud Data Platform",
                text: "The next step was to centralize SynthAI data within the Snowflake Data Cloud, which offers a unified platform with an elastic performance engine. Data from all Snowflake accounts was consolidated into a single account, with additional data from other sources ingested and processed in real-time. To prepare for future data analytics workloads, the data architecture was redesigned, enabling each data processing unit to operate independently. The goal was to create resilient and well-governed data pipelines.",
                image: cardImg
            },
            {
                title: "Enhancing Data Consumption & Retrieval",
                text: "Once the data was centralized in the data warehouse, the focus shifted to ensuring on-demand, near real-time data availability. The Snowflake Data Cloud powered the front-end data applications, and the pipelines were designed with performance as a critical factor.",
                image: cardImg
            },
            {
                title: "Cost Monitoring & Optimization",
                text: "Additionally, SynthAI aimed to optimize costs within Snowflake and minimize operational overhead with minimal downtime. SynthAI collaborated with Snowflake to develop a customized cost-tracking setup tailored to Company Name specific needs. This resulted in cost-tracking improvements through:",
                list: [
                    "Implementation of tagging policies on resources",
                    "Automation of a cost-tracking dashboard",
                    "Development of a warehouse and workload monitoring dashboard"
                ],
                image: cardImg
            },
            {
                title: "Impact",
                text: "Thanks to the solutions developed by the SynthAI team, SynthAI can now provision a Snowflake environment for any new team within 15 minutes, a significant reduction from the previous two-hour timeframe. Furthermore, SynthAI data analytics architecture has been fully revamped, reducing data processing and transformation time to one-tenth of the previous requirements. These solutions also automated real-time data validation at a granular level and streamlined the onboarding process for new teams. Coupled with Snowflake’s data-sharing feature, the Data-as-a-Service (DaaS) implementation reduced total onboarding time from five hours to just 15 minutes. The implementation of monitoring and cost-tracking mechanisms has significantly improved SynthAI budgeting process for data warehouse utilization, enabling more effective resource allocation based on team needs.",
                image: cardImg
            },
            {
                title: "Technology Stack",
                text: "",
                list: [
                    "Snowflake", "Azure", "Azure Data Factory", "SQL Server"
                ],
                image: cardImg
            },
            {
                title: "About SynthAI",
                text: "SynthAI helps businesses bridge data gaps and deliver rapid insights at scale. With Snowflake as our foundation, we believe that quality data can drive limitless innovation, enabling you to move beyond complex data solutions and embrace the modern world of cloud elasticity. SynthAI was honored with Snowflake’s Americas System Integrator Growth Partner of the Year Award at the 2023 Snowflake Summit and has earned seven industry competency badges. Let SynthAI become your trusted partner for data and analytics, empowering your teams with data-driven insights and unlocking new revenue streams at scale.",
            }
        ],
    },
    {
        title: "Research Report",
        description: "A Comprehensive Approach to Healthcare Data Management",
        image: cardImg,
        list: [
            {
                title: "Introduction",
                text: "Patient 360 represents a groundbreaking approach to healthcare, designed to aggregate, integrate, and analyze diverse patient data, creating a complete and holistic view of an individual's health. This innovative strategy combines medical records, test results, treatment plans, medication history, lifestyle factors, and patient-reported information to deliver a unified view of a patient’s health journey.\nBy equipping healthcare providers with a thorough and up-to-date understanding of a patient’s health, Patient 360 facilitates informed decision-making, personalized treatment plans, and enhanced overall care quality. This data-driven approach addresses the challenges of fragmented and siloed patient information, empowering healthcare professionals to deliver patient-centric care and achieve better health outcomes.",
                image: cardImg
            },
            {
                title: "Key Challenges",
                list: [
                    {
                        title: "Data Fragmentation",
                        text: "Patient data is often dispersed across multiple systems, including electronic medical records (EMRs), lab databases, imaging systems, and specialized software. This fragmentation hinders healthcare providers from accessing a comprehensive and unified view of a patient’s health information."
                    },
                    {
                        title: "Lack of Interoperability",
                        text: "Healthcare software is notorious for poor integration, leading to excessive friction between systems. This results in patient dissatisfaction due to compromised care quality and creates significant operational inefficiencies for providers."
                    },
                    {
                        title: "Privacy and Security Concerns",
                        text: "Protecting sensitive patient data and complying with privacy regulations, such as HIPAA, adds complexity to creating a unified view of patient health information."
                    },
                    {
                        title: "Data Quality and Completeness",
                        text: "Incomplete or inaccurate patient data can lead to misdiagnoses, inappropriate treatment plans, and suboptimal patient outcomes. Ensuring the accuracy and completeness of patient records is a major challenge in developing a Patient 360 solution."
                    },
                    {
                        title: "Change Management and Adoption",
                        text: "Introducing new software and systems can face resistance from healthcare professionals accustomed to existing solutions. Successfully adopting a Patient 360 approach requires strong change management strategies and continuous support."
                    },
                    {
                        title: "Scalability and Flexibility",
                        text: "Healthcare organizations need a Patient 360 solution that can efficiently handle large volumes of data while remaining flexible to accommodate the organization’s evolving needs, including new data sources, systems, and regulations."
                    }
                ]
            },
            {
                title: "The SynthAI Approach to Enabling Patient 360",
                text: "SynthAI offers a unique strategy for helping healthcare organizations implement Patient 360 using Snowflake, ensuring maximum value from the solution in the shortest time. By adopting a 360-degree approach, SynthAI ensures that all critical patient data is considered, leading to enhanced care coordination and improved patient outcomes. The SynthAI approach is built on five core pillars:"
            },
            {
                title: "Patient 360 Pillars",
                listType: "number",
                list: [
                    {
                        title: "Understand the Existing Data and Tool Ecosystem",
                        list: [
                            "Assess the provider's first, second, and third-party data architecture.",
                            "Navigate the complex medical data landscape to ensure all data is managed effectively.",
                            "Prioritize critical data based on its importance.",
                            "Develop robust metadata catalogs, enabling analysts and healthcare professionals to provide highly detailed and relevant insights to their patients."
                        ]
                    },
                    {
                        title: "Centralize on One Scalable Data Cloud",
                        list: [
                            "Efficiently consolidate the entire data catalog into centralized cloud storage.",
                            "Provide expert guidance on integrating multiple third-party medical software solutions."
                        ]
                    },
                    {
                        title: "Build a Whole-Health Data Model with Patient 360",
                        list: [
                            "Develop a common data model (CDM) to create a unified view of all patient data.",
                            "Leverage Snowflake’s advanced features to build a multi-layered data architecture.",
                            "Utilize experienced data engineers to handle the heavy lifting of categorization, curation, protection, and remediation as the data model is developed."

                        ]
                    },
                    {
                        title: "Unlock Effective Data Sharing and Consumption",
                        list: [
                            "Address interoperability challenges in medical software systems by eliminating barriers to effective care with Snowflake and Patient 360.",
                            "Provide functional and robust data access to key entities in the care process, including insurance providers, payers, researchers, data analysts, and healthcare providers."
                        ]
                    },
                    {
                        title: "5.	Patient 360 Reference Architecture",
                        list: [
                            "o	This multi-layered approach allows SynthAI to aggregate information from various sources, providing a comprehensive, 360-degree view of critical patient data."
                        ]
                    },
                ]
            },
            {
                title: "Benefits of Patient 360",
                listType: "number",
                list: [
                    {
                        title: "Enhanced Care Coordination",
                        list: [
                            "Patient 360 consolidates and centralizes patient data, enabling seamless communication and collaboration among healthcare providers. This ensures that all members involved in a patient’s care have access to the most up-to-date and relevant information, promoting seamless coordination and continuity of care."
                        ]
                    },
                    {
                        title: "Personalized Treatment Plans",
                        list: [
                            "A holistic view of a patient’s health history allows healthcare providers to tailor treatment plans to the specific needs and preferences of each individual. This personalized approach improves treatment efficacy, reduces the risk of adverse events, and enhances patient satisfaction."
                        ]
                    },
                    {
                        title: "Improved Clinical Decision-Making",
                        list: [
                            "Patient 360 empowers healthcare professionals to make more informed, evidence-based decisions by analyzing a comprehensive set of patient data. This leads to faster, more efficient identification of health concerns, detection of potential risks, and accurate diagnoses, ultimately resulting in improved clinical outcomes."
                        ]
                    },
                    {
                        title: "Preventative Care & Population Health Management",
                        list: [
                            "Patient 360 enables proactive healthcare by identifying high-risk patients and supporting preventive interventions. By analyzing population health data, healthcare organizations can identify trends, patterns, and risk factors, leading to the development of targeted preventive strategies that benefit society as a whole."
                        ]
                    },
                    {
                        title: "Patient Empowerment and Engagement",
                        list: [
                            "A comprehensive view of patient data encourages patients to take an active role in their healthcare journey. With Patient 360, patients can access their health information, track progress, and engage in shared decision-making with their healthcare providers. This fosters a sense of empowerment and enhances overall patient engagement."
                        ]
                    },
                    {
                        title: "Cost Savings and Improved Operational Efficiency",
                        list: [
                            "By centralizing and consolidating patient data, Patient 360 helps healthcare organizations reduce duplicate tests, minimize administrative burdens, and optimize resource allocation. This leads to cost savings and improved operational efficiency, benefiting both providers and patients."
                        ]
                    },
                    {
                        title: "Enhanced Patient Privacy and Security",
                        list: [
                            "Implementing Patient 360 on a secure data platform like Snowflake ensures that sensitive patient data is protected, while still allowing authorized healthcare providers to access the information necessary to deliver optimal care."
                        ]
                    },
                ]
            },
            {
                title: "About SynthAI",
                text: "SynthAI helps businesses bridge data gaps and deliver rapid insights at scale. With Snowflake as our foundation, we believe that quality data can drive limitless innovation, enabling you to move beyond complex data solutions and embrace the modern world of cloud elasticity.\nWe are dedicated to creating world-class data solutions for Snowflake customers, offering over 50 Accelerators, Enablers, Solutions, and Native Apps to enhance performance within Snowflake.\nLet SynthAI become your trusted partner for data and analytics, empowering your teams with data-driven insights and unlocking new revenue streams at scale."
            }
        ],
        id: 2
    },
    {
        title: "Research Report",
        description: "Insurance DataHub – Guidewire CDA Adapter",
        image: cardImg,
        list: [
            {
                title: "Executive Summary",
                text: "Snowflake’s native application for Guidewire CDA integration leverages Snowpark’s built-in capabilities to seamlessly integrate with Amazon S3, enabling efficient data ingestion. The solution is equipped with an intelligent schema change mechanism that handles dynamic schema drift. Additional features include preview and deploy modes, configurable scheduling, a base data model, and an analytics starter pack, all driven by metadata configurations.\nThis integration allows insurance companies to focus on analytics rather than data acquisition and management, while also ensuring compliance with industry and government regulations. By integrating Guidewire CDA with Snowflake, insurers can gain deeper insights from their historical data, identify trends, and make data-driven decisions to enhance their operations and maintain a competitive edge in the insurance market.\nIn summary, this solution empowers insurance companies to maximize the value of their historical claims data, drive informed decision-making, and improve overall efficiency in their business processes."
            },
            {
                title: "Framework Advantage",
                list: [
                    "<span>Guidewire CDA Ingestion Package:</span> Specialized tools for seamless data ingestion.",
                    "<span>Insurance Industry Expertise:</span> Deep knowledge and experience in the insurance sector.",
                    "<span>Metadata-Driven Development:</span> A dynamic, flexible approach to managing data.",
                    "<span>Compliance and Security:</span> Ensures adherence to industry standards and regulations.",
                    "<span>Robust Data Governance Practices:</span> Strong governance to maintain data integrity and security.",
                    "<span>Scalable Cloud Architecture:</span> A flexible, scalable infrastructure designed for growth.",
                    "<span>Enhanced Decision-Making:</span> Data-driven insights lead to better strategic decisions."

                ]
            },
            {
                title: "SynthAI Advantage",
                textAfterList: "Seamlessly connecting Guidewire and Snowflake, SynthAI’s proprietary Snowpark package ensures a smooth Guidewire CDA integration. Utilizing metadata-driven techniques, this approach enables agile development cycles, boosting efficiency and simplifying maintenance.",
                list: [
                    "<span>Seamless Integration:</span> Effortlessly merge data for unified experiences, improving customer interactions and streamlining processes.",
                    "<span>Cost Effective:</span> Achieve operational efficiency through streamlined data flows, reducing costs and optimizing resource use.",
                    "<span>Enhanced Analytics:</span> Gain deeper insights by combining data sources, empowering more effective strategies.",
                    "<span>Agile Responses:</span> Quickly adapt to market changes with data-driven decisions and responsive strategies."

                ]
            },
            {
                title: "Reference Architecture\nKey Data Sources & Integrations",
                text: "",
                list: [
                    {
                        title: "Data Sources/Formats:",
                        list: [
                            "AWS S3",
                            "Guidewire Insurance Suite",
                            "Change Data Capture (CDC)",
                            "Apache Parquet",
                            "Apache Kafka",
                            "AWS EventBridge"

                        ]
                    },
                    {
                        title: "Integrations:",
                        list: [
                            "Data Files(S3)",
                            "Bulk Data Load",
                            "AWS EventBridge"

                        ]
                    }
                ]
            },
            {
                title: "Differentiators",
                list: [
                    "<span>Guidewire CDA Integration Expertise:</span> Specialized knowledge in integrating Guidewire CDA.",
                    "<span>Guidewire CDA Data Ingestion Package:</span> A robust package designed for efficient data ingestion.",
                    "<span>Analytics Starter-Pack:</span> Tools to jumpstart your analytics initiatives.",
                    "<span>Snowpark:</span> Leverages Snowflake’s advanced features for optimal performance."

                ]
            },
            {
                title: "About SynthAI",
                text: "SynthAI helps businesses bridge data gaps and deliver rapid insights at scale. With Snowflake as our foundation, we believe that quality data can drive limitless innovation, enabling you to move beyond complex data solutions and embrace the modern world of cloud elasticity.\nWe are dedicated to creating world-class data solutions for Snowflake customers, offering over 50 Accelerators, Enablers, Solutions, and Native Apps to enhance performance within Snowflake.\nLet SynthAI become your trusted partner for data and analytics, empowering your teams with data-driven insights and unlocking new revenue streams at scale."
            },
        ],
        id: 3
    }

]