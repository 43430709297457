import React from 'react';
import './style.scss';
import { ServiceCard } from '../../../components';

const Index = () => {

    const serviceTabs = [
        {
            title: "Strategy & Advisory",
            description: "Discover insights and embark on a data-driven journey with high-performance strategic solutions."
        },
        {
            title: "Data Engineering",
            description: "Effortlessly transition to the Cloud, leveraging flexibility, scalability, and performance to build a strong foundation for your data."
        },
        {
            title: "BI & Analytics",
            description: "Unlock limitless analytical potential with SynthLake—where analytics knows no boundaries."
        },
        {
            title: "Advanced Analytics & Data Science",
            description: "Unlock the full potential of your data with SynthLake worldwide team of engineers, analysts, and snowflake experts."
        },
        {
            title: "Cost & Performance Optimization",
            description: "Let us take care of the optimization details, ensuring maximum efficiency and top-notch performance."
        },
        {
            title: "snowflake Native Apps & Data Apps",
            description: "Welcome to a new era of innovation, where SynthLake applications accelerate performance on snowflake."
        },
        {
            title: "AI & Machine Learning",
            description: "Redefining AI and ML with advanced solutions crafted for seamless integration and collaboration."
        },
        {
            title: "Managed Services",
            description: "As industry leaders, we envision a future where businesses thrive through our managed solutions."
        },
    ]

    return (
        <div className='services__main'>
            <div className='service__sub__container'>
                <div className='custom__container'>
                    <div className='service__flex__section'>
                        <h4>
                        Synth Lake solutions enable organizational<br />
                            transformation and deliver meaningful<br />
                            business outcomes</h4>
                        <div className='tabs__list'>
                            {serviceTabs?.map((v, i) => {
                                return <ServiceCard data={v} key={i} />
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index
