import React from 'react';
import './style.scss';
import { PrimaryBtn } from '../../../components';
import consulting from '../../../assets/images/assessment.jpg';


const Index = () => {
    return (
        <div className='consulting__section__main'>
            <div className='custom__container'>
                <h3 className='consulting__section__heading'>
                    Modernizing legacy technology improves security, increases speed to market, and accelerates innovation
                </h3>
                <div className='consulting__flex__section'>
                    <div className='text__section'>
                        <h5>
                            Consulting and assessments
                        </h5>
                        <p>
                        Creating a data modernization roadmap begins with evaluating and documenting your existing environment. We then collaborate to develop 
recommendations for updating applications, platforms, and IT operations, aligned with your business’s strategic objectives.

                        </p>
                        <p className='second__description'>
                            Consider adopting a DevOps best practices plan to promote a culture of business innovation and continuous process improvement.
                        </p>
                        <div className='btn__container'>
                            <PrimaryBtn text={"IT Consulting"} />
                            <PrimaryBtn classes="blue__bg" text={"The key to digital transformation"} />
                        </div>
                    </div>
                    <div className='image__section'>
                        <img src={consulting} alt='' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index
