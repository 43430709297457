import React from 'react';
import './style.scss';
import { FaArrowRight } from "react-icons/fa6";
import snowflake from '../../../assets/gifs/snowflake.gif';
import azure from '../../../assets/gifs/azure.gif';
import dbt from '../../../assets/gifs/dbt.gif';
import oracle from '../../../assets/gifs/aws.gif';

const Index = () => {


    const cards = [
        {
            title: "snowflake",
            description: `An agnostic approach to determining the value of emerging technologies to enhance each client’s current environment and achieve desired business outcomes.`,
            icon: snowflake
        },
        {
            title: "Azure",
            description: `A holistic approach to designing a personalized mix of interconnected cloud-native applications, platforms, and operations.`,
            icon: azure,
            svgIcon: <iframe
                style={{
                    width: 120,
                    height: 60,
                    marginLeft: -27,
                }}
                src="https://lottie.host/embed/40e7c986-1d6e-475f-b31a-25e1c97b8406/g8NzehwRoA.json"></iframe>
        },
        {
            title: "dbt",
            description: `Organizations can eliminate data silos and mitigate risk of data 360 view by partnering with a trusted advisor to enable integrations and transformations by building data models quickly.`,
            icon: dbt,
            svgIcon: <iframe
                style={{ height: 47, width: 80 }}
                src="https://lottie.host/embed/c44f6c25-aec5-42ec-8c41-6bb42bde7066/HZ4Co1KHRb.json"></iframe>
        },
        {
            title: "AWS",
            description: `AWS services to help you leverage the full potential of Amazon Web Services. From cloud migration and architecture design to security and management.`,
            icon: oracle,
            svgIcon: <iframe
                style={{
                    width: 110,
                    height: 70,
                    marginLeft: - 22,
                    marginTop: -9,
                    marginBottom: -8
                
                }}
                src="https://lottie.host/embed/4c9748e1-6b76-4f44-af7a-e943fb5ec6d1/iuA6NSeTku.json" ></iframe >
        },
    ]

    return (
        <div className='about__us__main'>
            <div className='custom__container'>
                <div className='text__section d-flex justify-content-between flex-wrap'>
                    <div className='heading__text'>
                        <h3>
                            Preparing for an <br />AI-driven future
                        </h3>
                        <p>
                            Discover what strategies, structures, and talent business leaders need to put in place to prepare their organizations for an AI-driven future.
                        </p>
                    </div>
                    <div className='card__section'>
                        <h4>

                            The AI revolution is in full throttle, with a record number of organizations rushing to adopt artificial intelligence to boost business operations,
                            increase productivity, inspire creativity, and fuel substantial growth.
                        </h4>
                        <div className='text-end'>
                            <button className='connect__btn'>
                                Let’s innovate together&nbsp;&nbsp;&nbsp;<FaArrowRight />
                            </button>
                        </div>
                    </div>
                </div>
                <div className='cards__section'>
                    {cards?.map((v, i) => {
                        return <div key={i} className={`${v?.title === "AWS" ? "is_aws" : ""} about__us__card`}>
                            {v?.svgIcon ? v?.svgIcon : <img src={v?.icon} alt={v?.title} />}
                            <h4>
                                {v?.title}
                            </h4>
                            <p dangerouslySetInnerHTML={{ __html: v?.description }} />
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default Index
