import React from 'react';
import './style.scss';

const Index = () => {

    const cards = [
        {
            title:"Global clients",
            number:"10",
        },
        {
            title:"Dedicated employees",
            number:"30",
        },
        // {
        //     title:"Annual revenue",
        //     number:"$1.3 B",
        // },
        {
            title:"Technology certified consultatns",
            number:"10",
        },
    ]

    return (
        <div className='numbers__section__main'>
            <div className='custom__container'>
                <h4>
                Synth Lake by the numbers
                </h4>
                <div className='cards__section'>
                    {cards?.map((v,i)=>{
                        return <div key={i} className='number__card'>
                                <h3>
                                    {v?.number}+
                                </h3>
                                <p>
                                    {v?.title}
                                </p>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default Index