import React, { useState } from 'react';
import './style.scss';
import { ChallengeCard } from '../../../components';

const Index = () => {

    const [currentTab, setCurrentTab] = useState("Challenges")

    const tabs = ["Challenges", "Solutions", "Outcomes"]

    const changeTab = (t) => setCurrentTab(t);

    const data = {
        Challenges:{
            description:"Over time, technology ages, software becomes outdated, and vulnerable, legacy systems crawl as critical issues negatively impact revenue.",
            list:[
                {
                    percent:90,
                    description:"of businesses are not harnessing the full potential of digital technologie",
                    linkName:"McKinsey & Company",
                    link:"https://www.mckinsey.com/capabilities/mckinsey-digital/our-insights/rewired-to-outcompete"
                },
                {
                    percent:38,
                    description:"of businesses experience delays in migration projects by one quarter or more.",
                    linkName:"McKinsey & Company",
                    link:"https://www.mckinsey.com/industries/technology-media-and-telecommunications/our-insights/cloud-migration-opportunity-business-value-grows-but-missteps-abound"
                },
                {
                    percent:63,
                    description:"of companies have experienced a data compromise or breach within the past 12 months.",
                    linkName:"Dell",
                    link:"https://www.delltechnologies.com/asset/en-ae/solutions/business-solutions/industry-market/dell-bios-security-the-next-frontier-for-endpoint-protection.pdf"
                },
            ]
        },
        Solutions:{
            description:"From consulting to design and implementation to ongoing management and optimization, our expert professionals have the deep technology experience to transform your technology environment into a growth engine for your organization.",
            list:[
                {
                    percent:90,
                    description:"of companies see cloud technology as essential for growth.",
                    linkName:"Deloitte",
                    link:"https://www2.deloitte.com/ro/en/pages/about-deloitte/articles/studiu-deloitte-din-companii-considera-ca-tehnologia-cloud-este-esentiala-pentru-dezvoltare-transformare-digitala-si-competitivitate-pe-piata.html"
                },
                {
                    percent:89,
                    description:"of board directors say digital transformation is embedded in all business growth strategie",
                    linkName:"Gartner",
                    link:"https://www.gartner.com/en/newsroom/press-releases/2022-10-19-gartner-says-89-percent-of-board-directors-say-digital-is-embedded-in-all-business-growth-strategies"
                },
                {
                    percent:89,
                    description:"of senior executives say digital transformation is an organizational priority.",
                    linkName:"Gartner",
                    link:"https://www.gartner.com/en/information-technology/topics/digital-transformation"
                },
            ]
        },
        Outcomes:{
            description:"By modernizing infrastructure, migrating apps to the Cloud, and mitigating security risks, businesses can achieve outcomes that drive significant growth.",
            list:[
                {
                    percent:39,
                    description:"reduction in IT spend after adopting serverless computing.",
                    linkName:"AWS",
                    link:"https://pages.awscloud.com/rs/112-TZM-766/images/known-business-value-of-cloud-%20modernization-012022.pdf"
                },
                {
                    percent:43,
                    description:"increase in organization revenue for highly modernized organizations.",
                    linkName:"AWS",
                    link:"https://pages.awscloud.com/rs/112-TZM-766/images/known-business-value-of-cloud-%20modernization-012022.pdf"
                },
                {
                    percent:13,
                    description:"faster time to resolve security incidents after adopting managed data.",
                    linkName:"AWS",
                    link:"https://pages.awscloud.com/rs/112-TZM-766/images/known-business-value-of-cloud-%20modernization-012022.pdf"
                },
            ]
        }
    }


    // const solutions = [
    //     {
    //         percent:90,
    //         description:"of companies see cloud technology as essential for growth.",
    //         linkName:"Deloitte",
    //         link:"https://www2.deloitte.com/ro/en/pages/about-deloitte/articles/studiu-deloitte-din-companii-considera-ca-tehnologia-cloud-este-esentiala-pentru-dezvoltare-transformare-digitala-si-competitivitate-pe-piata.html"
    //     },
    //     {
    //         percent:89,
    //         description:"of board directors say digital transformation is embedded in all business growth strategie",
    //         linkName:"Gartner",
    //         link:"https://www.gartner.com/en/newsroom/press-releases/2022-10-19-gartner-says-89-percent-of-board-directors-say-digital-is-embedded-in-all-business-growth-strategies"
    //     },
    //     {
    //         percent:89,
    //         description:"of senior executives say digital transformation is an organizational priority.",
    //         linkName:"Gartner",
    //         link:"https://www.gartner.com/en/information-technology/topics/digital-transformation"
    //     },
    // ]

    // const outcomes = [
    //     {
    //         percent:39,
    //         description:"reduction in IT spend after adopting serverless computing.",
    //         linkName:"AWS",
    //         link:"https://pages.awscloud.com/rs/112-TZM-766/images/known-business-value-of-cloud-%20modernization-012022.pdf"
    //     },
    //     {
    //         percent:43,
    //         description:"increase in organization revenue for highly modernized organizations.",
    //         linkName:"AWS",
    //         link:"https://pages.awscloud.com/rs/112-TZM-766/images/known-business-value-of-cloud-%20modernization-012022.pdf"
    //     },
    //     {
    //         percent:13,
    //         description:"faster time to resolve security incidents after adopting managed data.",
    //         linkName:"AWS",
    //         link:"https://pages.awscloud.com/rs/112-TZM-766/images/known-business-value-of-cloud-%20modernization-012022.pdf"
    //     },
    // ]

    return (
        <div className='challenges__section__main'>
            <div className='custom__container'>
                <div className='tabs__section'>
                    {tabs?.map((v, i) => {
                        return <button onClick={()=> changeTab(v)} className={`tab__btn ${v === currentTab ? "active__tab" : ""}`} key={i}>
                            {v}
                        </button>
                    })}
                </div>
                <div className='tabs__info__section'>
                        <div>
                            <p className='description'>
                                {data[currentTab]?.description}
                            </p>
                            <div className='list__section'>
                                {data[currentTab]?.list?.map((v,i)=>{
                                    return <ChallengeCard data={v} key={i} />
                                })}
                            </div>
                        </div>
                  
                </div>
            </div>
        </div>
    )
}

export default Index