import React, { useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './styles.scss';


export default function Index({ slides }) {

  const [activeSlide, setActiveSlide] = useState(0);

  const onSlideChange = (e) => {
    setActiveSlide(e.realIndex)
  }

  return (
    <>
      <Swiper
        slidesPerView={1}
        grabCursor={true}
        className="testimonial__carousel"
        onRealIndexChange={onSlideChange}
      >
        {slides?.map((v, i) => {
          return <SwiperSlide>
            <div className='testimonial__card'>
              <p>
                {v?.description}
              </p>
              <div className='reviewer'>
                {/* <img src={v?.image} alt='' /> */}
                <div>
                  {/* <p>
                    - {v?.name}
                  </p> */}
                  {/* <h4>
                    {v?.title}
                  </h4> */}
                </div>
              </div>
            </div>
          </SwiperSlide>
        })}
      </Swiper>
      <div className='custom__pagination'>
        {slides?.map((v, i) => {
          return <div key={i} className={`bullet__container ${activeSlide === i ? "active__bullet" : ""}`}>
            •
          </div>
        })}
      </div>
    </>
  );
}
