import React from 'react'
import './style.scss'
import { MdNavigateNext } from "react-icons/md";


const Index = ({ data }) => {
    const { Icon } = data;
    return (
        <a href='/' className='hover__card__main'>
            <div className='icon__section'>
                <div className='icon__container'>
                    <Icon size={24} color="#fff" />
                    </div>
            </div>
            <div className='content__section'>
                <h4>{data?.title}</h4>
                <p>
                    {data?.description}
                </p>
                <div className='text-center'>
                    <button>
                        Learn More <MdNavigateNext />
                    </button>
                </div>
            </div>
        </a>
    )
}

export default Index