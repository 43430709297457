import React from 'react';
import './style.scss';
import { Footer, Navbar } from '../../components';
import { AboutUs, ChallengesSection, Clients, ConsultingSection, Hero, NumbersSection, Services, Testimonial } from '../../layouts';

const Index = () => {
    return (
        <div>
            <Navbar />
            <Hero />
            <AboutUs />
            <ConsultingSection />
            <Services />
            <ChallengesSection />
            {/* <CaseStudies /> */}
            <Clients />
            <NumbersSection />
            <Testimonial />
            <Footer />
        </div>
    )
}

export default Index
