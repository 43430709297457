import React, { useState } from 'react';
import './style.scss';

const Index = ({ data }) => {

    const [hovered, setHovered] = useState(false);

    const onMouseOver = ()=> setHovered(true);
    const onMouseOut = ()=> setHovered(false);

    return (
        <div onMouseOver={onMouseOver} onMouseOut={onMouseOut} className='service__card__main'>
            <h5>
                {data?.title}
            </h5>
            {hovered && <p>
                {data?.description}
            </p>}
        </div>
    )
}

export default Index
