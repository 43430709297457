import React from 'react';
import './style.scss';
import { IoMdClose } from "react-icons/io";

const Index = ({ videoId, onClose }) => {
    return (
        <div className='video__modal__container'>
            <button onClick={onClose} className='close__btn'>
                <IoMdClose color='#fff' size={35} />
            </button>
            <iframe
                width={"60%"}
                src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                style={{ aspectRatio: 112 / 63 }}
            />
        </div>
    )
}

export default Index;