import Form from 'react-bootstrap/Form';

function Index({ options, changeTab }) {

    const handleChange = (e) => {
        const { value } = e?.target;
        changeTab(value)
    }

    return (
        <Form.Select onChange={handleChange} aria-label="Default select example">
            {options.map((v, i) => {
                return <option key={i} value={v}>{v}</option>
            })}
        </Form.Select>
    );
}

export default Index;