import React from 'react';
import './style.scss';
import { HoverCard } from '../../../components';
import snowflakeservice from '../../../assets/images/snowflakeservices.webp'
import { GiArtificialHive } from "react-icons/gi";
import { IoCodeSlashOutline } from "react-icons/io5";
import { GiTeamIdea } from "react-icons/gi";
import { MdOutlineDatasetLinked } from "react-icons/md";
import { MdWaves } from "react-icons/md";
import { FaDatabase } from "react-icons/fa6";


const Index = () => {

    const cards = [
        {
            title: "AI/ML",
            Icon: GiArtificialHive,
            description: "Security build and deploy LLMs and ML models"
        },
        {
            title: "Applications",
            Icon: IoCodeSlashOutline,
            description: "Security build and deploy LLMs and ML models"
        },
        {
            title: "Collaboration",
            Icon: GiTeamIdea,
            description: "Security build and deploy LLMs and ML models"
        },
        {
            title: "Data Engineering",
            Icon: MdOutlineDatasetLinked,
            description: "Security build and deploy LLMs and ML models"
        },
        {
            title: "Data Lake",
            Icon: MdWaves,
            description: "Security build and deploy LLMs and ML models"
        },
        {
            title: "Data Warehouse",
            Icon: FaDatabase,
            description: "Security build and deploy LLMs and ML models"
        },
        // {
        //     title: "Unistore",
        //     Icon:GrDocumentStore,
        //     description: "Security build and deploy LLMs and ML models"
        // },
    ]

    return (
        <div className='workload__container'>
            <div className='custom__container'>
                <div className='header__section'>
                    <h3>
                        BRING WORKLOADS<br />
                        <span>TO THE DATA</span>
                    </h3>
                    <p>
                    Bring more workloads, users and use cases directly to your data, and connect with the most relevant content all within the AI Data Cloud.
                    </p>
                </div>
                <div className='flex__section'>
                    <div className='cards__section'>
                        {cards?.map((v, i) => {
                            return <HoverCard data={v} key={i} />
                        })}
                    </div>
                    <div className='image__section'>
                        <img src={snowflakeservice} alt='' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index