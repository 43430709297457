import React, { useEffect } from 'react';
import './style.scss';
import cardImg from '../../../assets/images/heroBg.png'


const Index = () => {

    const cardData = [
        {
            title: "Strategy & Advisory",
            description: "Kickstart your data-driven journey with a strategic approach crafted to advance your business. Let our analysts help you develop modern, integrated data applications, uncover new  revenue opportunities, and maximize the value of your snowflake investment. Transform your industry with pioneering data solutions that redefine success and fuel innovation.",
            image: cardImg
        },
        {
            title: "Data Engineering",
            description: "Establish a strong foundation for data analytics and insights with our Data Engineering services. Our Snowpro-certified experts utilize extensive data knowledge, cloud expertise, and leading technologies to democratize your data and foster innovation.",
            image: cardImg
        },
        {
            title: "BI & Analytics",
            description: "Experience BI and Analytics without limitations. Enhance your decision-making with advanced analytics solutions that set new industry standards. Harness the power of custom BI solutions and cutting-edge technologies to drive your business forward. Embrace innovative analytics strategies tailored to your industry.",
            image: cardImg
        },
        {
            title: "Advanced Analytics & Data Science",
            description: "Explore the snowflake cloud with our state-of-the-art Data Science solutions. Experience the full potential of Data Science, where expertise and innovation converge to shape a data-driven future. Enhance your insights with the precision and expertise that define our approach to Data Science.",
            image: cardImg
        },
        {
            title: "Cost & Performance Optimization",
            description: "Harness the power of snowflake native applications to drive innovation and achieve unmatched performance in the data landscape. SynthLake leads the way in snowflake Native App Development, consistently publishing new applications every week.",
            image: cardImg
        },
        {
            title: "snowflake Native Apps & Data Apps",
            description: "We effortlessly incorporate cutting-edge AI and Machine Learning technology into high-performance data systems, delivering business insights that give our customers a competitive edge. Embrace the cloud and collaborate with data experts who are leading the charge toward a brighter digital future.",
            image: cardImg
        },
        {
            title: "AI & Machine Learning",
            description: "We effortlessly incorporate cutting-edge AI and Machine Learning technology into high-performance data systems, delivering business insights that give our customers a competitive edge.",
            image: cardImg
        },
        {
            title: "Managed Services",
            description: "Experience the convenience of Managed Services with SynthLake. We prioritize efficiency, security, and responsiveness to meet your needs, enabling you to focus on your core business while we handle the complexities of data management.",
            image: cardImg
        },

    ]

    const [isSmallLaptop, setisSmallLaptop] = React.useState(false)

    // const isSmallLaptop = window.innerWidth < 981 && window.innerWidth > 768;

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            if(window.innerWidth < 981 && window.innerWidth > 768){
                setisSmallLaptop(true)
            }else{
                setisSmallLaptop(false)
            }
        })
    },[])


    return (
        <div className='modernization__main'>
            <div className='custom__container'>
                <div className='header__section'>
                    <h4>
                        Moderanization and Democratization
                    </h4>
                    <p>
                        Explore the Path to <span>Analytical Excellence</span> With Us
                    </p>
                </div>
                <div className='cards__section'>
                    {cardData?.map((v,i)=>{
                        return <div className='modernization__card' >
                            <div className='content__section'>
                                <h4>
                                    {v?.title}
                                </h4>
                                <p dangerouslySetInnerHTML={{__html:v?.description}} />
                            </div>
                        </div>
                    })}
                    {isSmallLaptop && <div className='empty__modr' />}
                </div>
            </div>
            <div className='colored__div' />
        </div>
    )
}

export default Index