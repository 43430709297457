import React from 'react';
import './style.scss';
import { Footer, Navbar } from '../../components';
import { InnovationAccelerator, InnovationHero, InnovationStrategies } from '../../layouts';
import { dataSharingData, dataSharingTabs, rbacAcceleratorData, rbacAcceleratorTabs } from '../../utils/data';

const Index = () => {

    return (
        <div>
            <Navbar />
            <InnovationHero />
            <InnovationAccelerator
                heading1="Synthlake Data"
                heading2="Accelerators"
                description="Utilize our suite of accelerators to revolutionize your company's operations. Each accelerator is intended to increase your company's efficiency and yield measurable results. Our Accelerators are the driving forces behind the development of your company—they are more than just tools."
                tabs={rbacAcceleratorTabs} data={rbacAcceleratorData} />
            <InnovationAccelerator
                tabs={dataSharingTabs} data={dataSharingData} reverseRow />
                <InnovationStrategies />
            <Footer />
        </div>
    )
}

export default Index
