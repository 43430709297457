import React from 'react';
import './style.scss';

const Index = ({ text, onClick }) => {
    return (
        <button onClick={onClick} className='btn__without__outline'>
            {text}
        </button>
    )
}

export default Index
