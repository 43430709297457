import React from 'react';
import './style.scss';
import { FullWidthCarousel } from '../../../components';
import carouselImg from '../../../assets/images/snowflake.png';


const Index = () => {

  const slides = [
    {
      title:"Data-driven Business Reimagined",
      description:"Maximize Your Business Potential Through Data Analytics",
      image:carouselImg
    },
    // {
    //   title:"Data-driven Business Reimagined",
    //   description:"Maximize Your Business Potential Through Data Analytics",
    //   image:carouselImg
    // },
    // {
    //   title:"Data-driven Business Reimagined",
    //   description:"Maximize Your Business Potential Through Data Analytics",
    //   image:carouselImg
    // },
    // {
    //   title:"Data-driven Business Reimagined",
    //   description:"Maximize Your Business Potential Through Data Analytics",
    //   image:carouselImg
    // },
  ]

  return (
    <div className='snowflake__hero__section'>
        <FullWidthCarousel textBlue slides={slides} />
    </div>
  )
}

export default Index