import './App.scss';
import AppRouter from './src/config/AppRouter';

function App() {

  return (
    <AppRouter />
  );
}

export default App;
