import React from 'react';
import './style.scss';
import artichecture from '../../../assets/images/dbtoffers/DataAI.webp';
import dbtdevelopment from '../../../assets/images/dbtoffers/Azure-MLOps.webp';
import infrastructure from '../../../assets/images/dbtoffers/icon-app-reengine.webp';

const Index = () => {

    const cards = [
        {
            title: "Architecture",
            description: "Our dbt architecture services help customers integrate dbt into their modern data stack and create end-to-end pipelines using Snowflake on Azure, AWS, and other cloud-agnostic stacks. Anblicks architecture services provide a comprehensive approach to ensure the data pipelines are efficient, secure, and scalable.",
            icon: artichecture
        },
        {
            title: "dbt Development",
            description: "A group of dbt development specialists at Anblicks specializes in building scalable, dependable, high-performance data pipelines that are customized to meet the specific needs of every customer. We collaborate closely with our clients to pinpoint areas in need of development, provide specialized solutions utilizing agile techniques, and streamline current workflows.",
            icon: dbtdevelopment
        },
        {
            title: "Infrastructure Modernization",
            description: "Through the use of cutting-edge, scalable, and effective data warehouse solutions, our team of trained DBT consultants assists businesses in optimizing their data infrastructure. Infrastructure modernization and dbt support are two of our dbt services.",
            icon: infrastructure
        },
        {
            title: "Data Transformation",
            description: "Our area of expertise is creating and executing pipelines, data transformations, and models to streamline procedures. In order to facilitate quicker, more precise data-driven decision making, we offer optimization and support as part of our DBT consulting services for data transformation.",
            icon: artichecture
        },
        {
            title: "Connect Natively to Snowflake",
            description: "Anblicks specializes in leveraging dbt to link enterprises natively to Snowflake, hence optimizing their data transformation workflows. To ensure optimal speed, security, and scalability, we offer Snowflake Optimization, Dbt Implementation, and Architecture Design.",
            icon: dbtdevelopment
        },
    ]

    return (
        <div className='dbt__offers__main'>
            <div className='custom__container'>
                <h3 className='offer__heading'>
                    Our Offerings
                </h3>
                <div className='cards__section'>
                    {cards?.map((v, i) => {
                        return <div key={i} className='offer__card'>
                            <img alt='' src={v?.icon} />
                            <h5>
                                {v?.title}
                            </h5>
                            <p>
                                {v?.description}
                            </p>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default Index