import React from 'react';
import './style.scss'
import { PrimaryBtn, Video } from '../../../components';
import heroVideo from '../../../assets/videos/heroVideo.mp4';
import heroThumbnail from '../../../assets/images/heroBg.png';
// import heroRoundImage from '../../../assets/images/heroRoundImage.png';

const Index = () => {

  // const buttons = [
  //   {
  //     name:"Ebook: Navigation new frontiers in security",
  //     showArrow:true
  //   },
  //   {
  //     name:"Learn more: The Future of AI Security",
  //     showArrow:false
  //   },
  //   {
  //     name:"Modern Security Approaches",
  //     showArrow:true
  //   },
  // ]

  return (
    <div className='hero__main__section'>
      <div className='video__container'>
        <Video thumbnail={heroThumbnail} videoUrl={heroVideo} />
      </div>

      <div className='custom__container hero__flex__section'>
        <div className='content__section'>
          <h1>
            A Better Road Map for AI
          </h1>
          <p>
            The emergence of AI, or artificial intelligence, has been one of the most transformative developments in recent technology. The impact of AI is
            profound and multifaceted, affecting industries ranging from healthcare and finance to entertainment and transportation. It has the potential to
            enhance productivity, create new opportunities, and solve complex problems. As AI continues to advance, it will likely play an increasingly
            central role in shaping the future.          </p>
          <div className='btn__container'>
            <PrimaryBtn text={"Top Concerns for CIOs"} />
          </div>
          {/* <div className='action__btns__section'>
            {buttons?.map((v,i)=>{
              return <AnimateButton showArrow={v?.showArrow} text={v?.name} key={i} />
            })}
            
          </div> */}
        </div>
        <div className='image__section'>
          <iframe title='lottie' style={{ width: "100%", height: 500 }} src="https://lottie.host/embed/9bf9d394-05b8-4d8c-855f-1e7350242db3/ArouWkj93F.json"></iframe>
        </div>
      </div>
      {/* <div className='curve__image__bg' /> */}
    </div>
  )
}

export default Index