import React from 'react';
import './style.scss';
import { Footer, Navbar } from '../../components';
import { MeetOurTeam, Modernization, SnowflakeHero, SuccessStories, Workload } from '../../layouts';

const Index = () => {
    return (
        <div>
            <Navbar />
            <SnowflakeHero />
            <Modernization />
            {/* <Competenties /> */}
            <MeetOurTeam />
            <Workload />
            <SuccessStories />
            <Footer />
        </div>
    )
}

export default Index
