import React from 'react';
import './style.scss';

import { SuccessStoriesCarousel } from '../../../components';
import { cardData } from '../../../utils/constants';


const Index = () => {


    return (
        <div className='competenties__main'>
            <div className=''>
                <SuccessStoriesCarousel slides={cardData} />
            </div>
        </div>
    )
}

export default Index