import React from 'react'
import './style.scss'

const Index = ({ title, hideDescription }) => {
    return (
        <div className='success__stories__main'>
            {title ? <h2 dangerouslySetInnerHTML={{ __html: title }} /> : <h2>
                Real-World <span>Success Stories</span>
            </h2>}
            {hideDescription ? "" : <p>
                Discover How Our Solutions Drive Tangible Results
            </p>}
        </div>
    )
}

export default Index