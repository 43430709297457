import React from 'react'
import './style.scss';
import { MultiCarousel, PrimaryBtn } from '../../../components';
import aws from '../../../assets/images/clients/aws.png';
import dbt from '../../../assets/images/clients/dbt.webp';
import azure from '../../../assets/images/clients/azure.png';
import snowflake from '../../../assets/images/clients/snowflake.png';

const Index = () => {

    const clients = [
        {
            image: snowflake,
            width: '200px'
        },
        {
            image: dbt,
            maxHeight:100
        },
        {
            image: aws,
            maxHeight:80
        },
        {
            image: azure,
            width: '200px'
        },
       
    ]

    return (
        <div className='clients__section'>
            <div className='custom__container'>
                <h5>
                    Long-standing strategic partnerships with industry leaders
                </h5>
                <div className='carousel__section'>
                    <MultiCarousel slides={clients} />
                </div>
                <div className='btn__section'>
                    <PrimaryBtn text={"View All Partnerships"} />
                </div>
            </div>
        </div>
    )
}

export default Index