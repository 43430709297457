import { DBT, Home, Snowflake, Innovation, SuccessStories, SuccessStoryDetail } from "../pages";

export const routes = [
  {
    path: "/",
    element: (
      <Home />
    ),
    title: "Home | Synth Lake",
  },
  {
    path: "/snowflake",
    element: (
      <Snowflake />
    ),
    title: "snowflake | Synth Lake",
  },
  {
    path: "/dbt",
    element: (
      <DBT />
    ),
    title: "dbt | Synth Lake",
  },
  {
    path: "/innovation",
    element: (
      <Innovation />
    ),
    title: "Innovation | Synth Lake",
  },
  {
    path: "/success-stories",
    element: (
      <SuccessStories />
    ),
    title: "Success Stories | Synth Lake",
  },
  {
    path: "/success-story/:id",
    element: (
      <SuccessStoryDetail />
    ),
    title: "Success Stories | Synth Lake",
  },
]

export const navigateWithParams = (navigate, path, params) => {
  const queryString = new URLSearchParams(params).toString();
  navigate(`${path}?${queryString}`);
};